<template>
  <layout :loading="loading">
    <h2>Employees' Personal Files</h2>
    <b-card>
      <div class="table-responsive table-sticky-last w-100">
        <table class="table mb-0" ref="table">
          <thead>
          <th style="width: 50px">#</th>
          <th class="text-left name-column">Name and position</th>
          <th class="text-left" width="200">
            <dropdown-select
                v-model="filters.department"
                :options="departments"
                valueKey="id"
                label="title"
                allOptionsText="All Departments"
            />
          </th>
          <th class="text-left">Link</th>
          <th width="80" />
          </thead>
          <tbody>
          <tr
              v-for="(item, index) in list"
              :key="item.id"
          >
            <td>{{index + 1}}</td>
            <td>
              <div class="d-flex align-content-center">
                <div class="avatar">
                  <b-avatar
                      size="50px"
                      class="mr-3"
                      variant="info"
                      :src="item.photo"
                  />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <p class="mb-0 font-weight-bold">{{ item.name }}</p>
                  <p class="mb-0"> {{ item.department }} </p>
                </div>
              </div>
            </td>
            <td>{{ item.department }}</td>
            <td class="td-link">
              <div
                  v-if="!item.isEdit"
                  class="d-flex align-items-center"
              >
                <a :href="item.link" target="_blank">
                  <p class="text-clip m-0">
                    {{ item.link }}
                  </p>
                </a>
                <template v-if="item.link">
                  <i
                      :id="`copy-button-${index}`"
                      class="bx bx-copy text-primary cursor-pointer ml-2 font-size-18"
                      @click="handleCopyToClipBoard(item.link, index)"
                  />
                  <b-popover
                      :show.sync="item.showPopover"
                      :target="`copy-button-${index}`"
                      variant="info"
                  >
                    Copied!
                  </b-popover>
                </template>
              </div>
              <div v-else>
                <b-form-input
                    :ref="`input-${index}`"
                    v-model="list[index].link"
                    type="text"
                    @keypress.enter="handleSaveLink(index)"
                />
              </div>
            </td>
            <td class="text-right">
              <i
                  v-if="!item.isEdit"
                  class="bx bx-pencil text-warning cursor-pointer font-size-20"
                  @click="handleEdit(index)"
              />
              <i
                  v-else
                  class="bx bx-save text-primary cursor-pointer font-size-20"
                  @click="handleSaveLink(index)"
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </b-card>
  </layout>
</template>

<script>
import {mapActions} from 'vuex';
import DropdownSelect from '@/components/dropdown-select.vue';

export default {
  name: 'PersonalFileLinks',
  data: () => ({
    loading: false,
    list: [],
    lastLinkValue: '',
    currentIndex: null,
    filters: {
      department: {
        id: null,
        title: 'All Departments'
      }
    }
  }),
  components: {
    DropdownSelect
  },
  async mounted() {
    this.loading = true;
    await this.getPersonalFilesLinks();
    this.loading = false;
    this.$refs.table.addEventListener('click', this.handleTableClick);
  },
  beforeDestroy() {
    this.$refs.table.removeEventListener('click', this.handleTableClick);
  },
  methods: {
    ...mapActions('personalFileLinks', [
      'savePersonalFileLink',
      'getPersonalFilesLinks'
    ]),
    handleTableClick(event) {
      if (
        this.currentIndex !== null &&
        this.list[this.currentIndex].isEdit &&
        event.target.tagName !== 'INPUT' &&
        !event.target.className.includes('bx-save')
      ) {
        this.handleCloseInput();
      }
    },
    handleEdit(index) {
      this.list = this.list.map((item, idx) => ({
        ...item,
        isEdit: index === idx ? true : false
      }));
      this.currentIndex = index;
      this.lastLinkValue = this.list[index].link;
      this.$nextTick(() => {
        this.$refs[`input-${index}`][0]?.focus?.();
      });
    },
    handleCloseInput() {
      this.lastLinkValue = '';
      this.list[this.currentIndex].isEdit = false;
      this.currentIndex = null;
    },
    async handleCopyToClipBoard(link, index) {
      try {
        await navigator.clipboard.writeText(link);
        this.list[index].showPopover = true;
        setTimeout(() => {
          this.list[index].showPopover = false;
        }, 1000);
      } catch (err) {
        console.error(err);
      }
    },
    async handleSaveLink(index) {
      if (this.lastLinkValue !== this.list[index].link) {
        this.loading = true;
        this.handleCloseInput();
        await this.savePersonalFileLink({
          link: this.list[index].link,
          employee_uid: this.list[index].uid
        });
        await this.getPersonalFilesLinks();
        this.loading = false;
      } else {
        this.handleCloseInput();
      }
    }
  },
  computed: {
    fileLinks() {
      return this.$store.state.personalFileLinks.list.map(item => item).filter(el => {
        return this.filters.department.id
            ? el.department.id === this.filters.department.id
            : el
      });
    },
    departments() {
      return this.$store.getters['personalFileLinks/getDepartments']
    }
  },
  watch: {
    fileLinks(value) {
      this.list = value.map((item) => ({
        name: item?.name ?? '',
        photo: item?.photo ?? '',
        department: item?.department?.title ?? '',
        link: item?.personal_file?.link ?? '',
        isEdit: false,
        showPopover: false,
        uid: item?.uid ?? ''
      }));
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  tr td {
    vertical-align: middle;
  }
  .td-link {
    a {
      width: 300px;
    }
  }
}
.name-column {
  width: 420px;
}
</style>
